import { render, staticRenderFns } from "./BoutiqueGoods.vue?vue&type=template&id=432ffce0&scoped=true&"
import script from "./BoutiqueGoods.vue?vue&type=script&lang=js&"
export * from "./BoutiqueGoods.vue?vue&type=script&lang=js&"
import style0 from "./BoutiqueGoods.vue?vue&type=style&index=0&id=432ffce0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432ffce0",
  null
  
)

export default component.exports