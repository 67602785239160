<template>
  <div class="goodsbox" v-if="goodslist.length > 0">
    <div style="margin-top: 40px; margin-bottom: 8px" class="top">
      <span class="title">{{ item.cate_name }}</span>
      <div @click="product_details" class="details">
        <a style="color: #5f5f5f">更多></a>
      </div>
    </div>
    <div class="center">
      <div class="imagebox">
        <img :src="item.big_pic" alt="" />
      </div>
      <GoodsList
        v-for="(item, index) in goodslist"
        :class="{ aaa: index == 3 }"
        :key="index"
        :item="item"
        ref="box"
      ></GoodsList>
    </div>
  </div>
</template>
<script>
import GoodsList from "../../components/common/GoodsList.vue";
import axios from "axios";
export default {
  name: "GoodsBox",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      goodslist: [],
    };
  },
  components: {
    GoodsList,
  },
  created() {
    let data = {
      keyword: "",
      sid: 0,
      cid: this.item.id,
      limit: 20,
    };
    axios
      .get(
        `https://fb.crmeb.fooktech.cn/api/front/foreign/getProductLst?keyword=${data.keyword}&sid=${data.sid}&cid=${data.cid}&page=${data.page}&limit=10`,
        {
          headers: {
            "shop-id": localStorage.getItem("id")
              ? localStorage.getItem("id")
              : 0,
          },
        }
      )
      .then((res) => {
        if (res.data.length <= 8) {
          this.goodslist = res.data.data.data;
        } else {
          this.goodslist = res.data.data.data.slice(0, 8);
        }
      });
  },
  methods: {
    box() {
      console.log(this.$refs.box);
    },
    product_details() {
      this.$router.push("/product_details");
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsbox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > .top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 15px 0;
    text-align: center;
    & > .title {
      font-size: 22px;
      font-weight: 700;
      color: #333;
    }
    & > .details {
      width: 58px;
      height: 24px;
      border: 1px solid #5f5f5f;
      display: block;
      font-size: 12px;
      cursor: pointer;
      line-height: 24px;
    }
  }

  & > .center {
    width: 100%;
    & > div {
      margin-top: 20px;
      margin-left: 20px;
      float: left;
    }
    & > .imagebox {
      width: 39%;
      height: 340px;
      cursor: pointer;
      &:hover {
        box-shadow: 3px 0 8px 2px rgba($color: #999, $alpha: 0.2);
      }
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    // &>:nth-child(5),
    & > .imagebox {
      margin-left: 0 !important;
    }
  }
  // &::before{
  //   content: "";
  //   display: block;

  //   clear: both;
  // }
}

.aaa {
  margin-left: 0 !important;
}
</style>