<template>
  <div class="BoutiqueBox">
    <div
      style="
        background: url('https://pro.crmeb.net/home/img/boutique1.bd72e2a.jpg');cursor: pointer;
      "
      @click="detail(goods_List[0])"
    >
      <div>
        <p v-if="goods_List[0]" style="margin-top:40px">{{ goods_List[0].store_name }}</p>
        <div>
          <span v-if="goods_List[0]">{{ goods_List[0].price }}</span>
          <span v-if="goods_List[0]">{{ goods_List[0].ot_price }}</span>
        </div>
      </div>
      <div v-if="goods_List[0]">
        <img :src="goods_List[0].image"  style="width:180px;height:180px"/>
      </div>
    </div>
    <BoutiqueGoods
      v-for="(item, index) in List"
      :key="index"
      :item="item"
    ></BoutiqueGoods>
  </div>
</template>
<script>
import BoutiqueGoods from "../../components/Home/BoutiqueGoods";
export default {
  name: "",
  props: {},
  data() {
    return {
      List: [],
      goods_List: [],
    };
  },
  components: {
    BoutiqueGoods,
  },
  methods: {
    detail(e) {
      this.$router.push({
        path: "/details",
        query: {
          data: e.id,
        },
      });
    },
    arr() {
      // 获取新品首发
      let data = {
        limit: 0,
        page: 0,
      };
      this.$api.groomlist(data).then((res) => {
      
        this.goods_List = res.data.list;
        this.List = res.data.list.splice(2, 2);
      });
    },
  },

  mounted() {
    // this.arr();
  },
  created() {},
  computed: {},
  watch: {},
};
</script>
<style lang='scss' scoped>
.BoutiqueBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  & > :first-child {
    width: 40%;
    height: 250px;
    padding: 28px;
    box-sizing: border-box;
    cursor: pointer;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    &:hover {
      box-shadow: 3px 0px 8px 1px rgba($color: #999, $alpha: 0.2);
    }
    & > :first-child {
      width: 60%;
      height: 194px;
      & > p {
        font-size: 16px;
        color: #282828;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }
      & > div {
        margin-top: 30px;
        & > :first-child {
          color: #e93323;
          font-size: 20px;
          font-weight: 700;
        }
        & > :last-child {
          color: #a3a3a3;
          margin-left: 12px;
          text-decoration: line-through;
          display: inline-block;
          margin-left: 15px;
        }
      }
    }
    & > :last-child {
      width: 40%;
      height: 194px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  & > :not(:first-child, :last-child):hover {
    box-shadow: 0px 0px 8px 2px rgba($color: #999, $alpha: 0.2);
  }
  & > :last-child:hover {
    box-shadow: -3px 0px 8px 2px rgba($color: #999, $alpha: 0.2);
  }
}
</style>