<template>
  <!-- <div class="goods-box"> -->
      <div class="BoutiqueGoods"
      
       @click="detail(item)">
        <p style="margin-top:24px">{{item.store_name}}</p>
        <div style="margin-top:55px">￥{{item.price}}</div>
        <div>￥{{item.ot_price}}</div>
        <div>
          <img :src="item.image" alt="">
        </div>
      </div>
    <!-- </div> -->
</template>
<script>
export default { 
  name:"BoutiqueGoods",
  data(){
    return{
      
    }
  },
  props:{
    item:{
      type:Object
    }
  },
  methods:{
    detail(e){
  
        this.$router.push({
             path:("/details"),
             query:{
                 data:e.id
             }
         })
    }
  }
}
</script>
<style lang="scss" scoped>
  .BoutiqueGoods{
    width: 330px;
    height: 250px;
    padding: 28px;
    position: relative;
    box-sizing: border-box;
    background: url('https://pro.crmeb.net/home/img/boutique1.bd72e2a.jpg');
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &>p{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
    }
    &>:nth-child(2){
      font-size: 16px;
      font-weight: 700;
      margin-top: 12px;
      color: #e93323;
    }
    &>:nth-child(3){
      color: #a3a3a3;
      text-decoration: line-through;
      margin-top: 4px;
    }
    &>:last-child{
      position: absolute;
      width: 130px;
      height: 130px;
      right: 28px;
      bottom: 28px;
      &>img{
        width: 100%;
        height: 100%;
      }
    }
  }
</style>