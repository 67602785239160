import axios from 'axios'; // 引入axios
import api from './api'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到

if (process.env.NODE_ENV == "development") { //开发环境
    axios.defaults.baseURL = 'https://crmeb.fooktech.cn'
    // axios.defaults.baseURL = 'https://os.fooktech.cn'
} else if (process.env.NODE_ENV == 'debug') { // 测试环境
    axios.defaults.baseURL = 'https://crmeb.fooktech.cn'
    // axios.defaults.baseURL = 'https://os.fooktech.cn'
} else if (process.env.NODE_ENV == 'production') { // 生产环境
    axios.defaults.baseURL = 'https://crmeb.fooktech.cn';
    // axios.defaults.baseURL = 'https://os.fooktech.cn'
}
axios.defaults.timeout = 15000;//响应时间
// 请求头部
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.set['shop-id'] = localStorage.getItem("id");


// 请求拦截器
// instance.interceptors.request.use(

//     config => {
//         let id = localStorage.getItem('id')??0;
//         if(localStorage.getItem('id')== 0 || localStorage.getItem('id') == null){
//            return console.log('等待加载shopId');
//         }
//         config.headers['shop-id']= localStorage.getItem('id')??0  
//         return config;

//     },
//     error => {

//         return console.log(111);;
//     })


export function axos(url, parmas, method) {

    return new Promise((resolve, reject) => {
        axios({
            method: method || "GET",
            url: "/api/" + url,
            data: parmas,
            headers: {
                'shop-id': localStorage.getItem('id') ?? 0
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function AXIOS(url, parmas, method) {

    axios.defaults.baseURL = 'https://fb.crmeb.fooktech.cn';
    return new Promise((resolve, reject) => {
        axios({
            method: method || "GET",
            url: "/api/" + url,
            data: parmas,
            headers: {
                'shop-id': localStorage.getItem('id') ?? 0
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}


